// core version + certain modules modules:
import SwiperCore, {Navigation, Pagination, Autoplay, EffectFade, Controller, A11y} from 'swiper/modules';

import Swiper from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Controller, A11y]);

const Swipers = {}

const testimonialSwiper = new Swiper('.testimonial-slider__slider', {
    autoplay: false,
    slidesPerView: 3,
    spaceBetween: 28,
    grabCursor: true,
    slidesPerView: 1,
    loop: false,
    a11y: true,
    navigation: {
        nextEl: '#testimonialSliderArrowNext',
        prevEl: '#testimonialSliderArrowPrev',
    },
    pagination: {
        el: '#testimonialSliderPagination',
        clickable: true,
    },
    breakpoints: {
        768: {
            slidesPerView: 2
        },
        992: {
            slidesPerView: 3
        },
    }
});

const imageSwiper = new Swiper('.image-slider__slider', {
    autoplay: false,
    slidesPerView: 1,
    grabCursor: true,
    slidesPerView: 1,
    loop: false,
    a11y: true,
    navigation: {
        nextEl: '#imageSliderArrowNext',
        prevEl: '#imageSliderArrowPrev',
    },
    pagination: {
        el: '#imageSliderPagination',
        clickable: true,
    },
});

