function mobileMenu() {

    const $toggle = document.getElementById("nav-toggle");
    const $menu = document.getElementById("menu-inner-wrapper");
    const $logo = document.getElementsByClassName("nav__logo")[0];

    $toggle.addEventListener("click", function () {
        if ($toggle.classList.contains("nav__toggle--inactive")) {

            $toggle.classList.replace("nav__toggle--inactive", 'nav__toggle--active');
            $menu.classList.replace("menu-inner-wrapper--inactive", 'menu-inner-wrapper--active');
            $logo.classList.add("nav__logo--active");

        } else {

            $toggle.classList.replace("nav__toggle--active", 'nav__toggle--inactive');
            $menu.classList.replace("menu-inner-wrapper--active", 'menu-inner-wrapper--inactive');
            $logo.classList.remove("nav__logo--active");

        }
    });
}

mobileMenu();
